import {StarIcon} from "@heroicons/react/24/solid";
import {useSubscriptionProvider} from "../providers/SubscriptionProvider";

interface SidebarItemProps {
    title?: string
    selected?: boolean
    onClick?: () => void
}

const SidebarItem = ({title, selected, onClick}:SidebarItemProps) => {
    const {validSubscription} = useSubscriptionProvider()
    return (
        <div
            onClick={onClick}
            className={"flex py-4 px-10 hover:bg-[#204345] cursor-pointer transition-all " + (selected && "bg-[#204345]")}>
            {validSubscription !== true && title?.toLowerCase().includes("abonnement") && (
                <StarIcon className={"w-5 mr-2"} />
            )}
            {title}
        </div>
    )
}

export default SidebarItem;