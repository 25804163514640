import PageHeading from "../../components/PageHeading";
import React from "react";
import MainButton from "../../components/MainButton";
import {useNavigate} from "react-router";
import {useUser} from "../../providers/UserProvider";
import {useSubscriptionProvider} from "../../providers/SubscriptionProvider";
import UpgradeButton from "../../components/UpgradeButton";
import ProFeatures from "../../components/ProFeatures";

const InsufficientQuotaPage = () => {
    const navigate = useNavigate()
    const {validSubscription} = useSubscriptionProvider()

    return (
        <div className={"w-full mt-[50vh] -translate-y-[50%] flex flex-col justify-center items-center"}>
            {validSubscription ? (
                <>
                    <h1 className="text-4xl font-bold text-gray-800 mb-4">
                        Du har nådd maksimalt antall søk
                    </h1>
                    <p className="text-lg text-gray-600 mb-4">
                        Beklager, men siden du leter etter finnes ikke.
                    </p>
                    <MainButton
                        className={"w-[200px]"}
                        onClick={() => navigate("/")}
                    >
                        Gå til Hjem
                    </MainButton>
                </>
            ) : (
                <div className={"w-full lg:w-[700px]"}>
                    <img className={"w-36 mx-auto mb-6"} src={"/images/crown.png"} alt={"Insufficient quota illustration"}/>
                    <h1 className="text-4xl font-bold text-gray-800 mb-4">
                        Oppgrader til&nbsp;
                        <span
                            className={"text-yellow-500 font-bold underline underline-offset-4"}>pro</span>
                        &nbsp;for å søke mer
                    </h1>
                    <p className="text-lg text-gray-600 mb-6">
                        På grunn av høye serverkostnader kan du kun skanne én salgsoppgave per måned uten abonnement.
                        Oppgrader nå for å få tilgang til mange flere skanninger!
                    </p>
                    <div className={"flex flex-col xl:max-w-[600px] mx-auto"}>
                        <div className={"border-gray-300 border-[1px] shadow-2xl bg-white rounded-xl"}>
                            <div className={"grid grid-cols-2 items-center p-6"}>
                                <div className={"text-left"}>
                                    <h2 className={"font-bold text-lg"}>
                                        Pro Plan
                                    </h2>
                                    <p>
                                        Vårt mest populære abonnement
                                    </p>
                                </div>
                                <div className={"text-right"}>
                                    <h2 className={"font-bold text-5xl"}>149 kr</h2>
                                    <p className={"text-gray-600"}>per mnd.</p>
                                </div>
                            </div>
                            <hr/>
                            <div className={"p-6 text-left"}>
                                <ProFeatures/>

                                <UpgradeButton
                                    className={"w-full"}
                                />

                                <p className={"text-sm text-center text-gray-600 mt-3"}>Vi sender deg melding èn dag
                                    før prøveperioden slutter</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default InsufficientQuotaPage