import {Link} from "react-router-dom";
import {useSubscriptionProvider} from "../../../providers/SubscriptionProvider";

const ComingSoonComponents = () => {
    const {validSubscription} = useSubscriptionProvider()

    return (
        <>
            <div className={"flex flex-row !mt-24 !mb-28 justify-center items-center w-full"}>
                <hr className={"border-2 border-main flex-grow"}/>
                {validSubscription ? (
                    <div>
                        <h2 className={"text-center text-3xl text-main"}>Kommer snart</h2>
                        <p className={"text-center py-1"}>Ditt
                            <span className={"text-yellow-500 font-bold"}>&nbsp;pro&nbsp;</span>
                            abonnement vil snart gi deg eksklusiv tilgang til mer funksjonalitet.
                        </p>
                    </div>
                ) : (
                    <div className={"flex flex-col w-64 mx-4 md:w-auto md:mx-10"}>
                        <h2 className={"text-center text-3xl text-main"}>Vil du se mer data?</h2>
                        <p className={"text-center py-1"}><Link to={"/abonnement"}
                                                                className={"text-main underline"}>Start en
                            prøveperiode</Link> og få tidlig tilgang.</p>
                    </div>
                )}
                <hr className={"border-2 border-main flex-grow"}/>
            </div>
            <img className={"w-full opacity-50 blur-sm"} src={"/templates/tilstandsgrad.jpg"}/>
            <img className={"w-full opacity-50 blur-sm"} src={"/templates/om_bolig_og_naeromraade.jpg"}/>
            <img className={"w-full opacity-50 blur-sm"} src={"/templates/egenerklaering.jpg"}/>
        </>
    )
}

export default ComingSoonComponents;