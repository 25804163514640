import {createContext, useContext, useEffect, useState} from "react";
import {User} from "../types/User";
import axios from "axios";
import {getBackendURL, isDevelopmentEnv} from "../utils/EnvironmentsManager";
import posthog from "posthog-js";

export interface UserHolder {
    currentUser?: User;
    refreshUser: () => void;
}

export const UserContext = createContext<UserHolder>({
    refreshUser: () => {}
})

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error("Must be used as useUser");
    }
    return context;
}

export interface UserContextProviderProps {
    children: React.ReactNode
}

export const UserProvider = ({children}: UserContextProviderProps) => {
    const [currentUser, setCurrentUser] = useState<User | undefined>()

    useEffect(() => {
        refreshUser()
    }, []);

    const refreshUser = () => {
        axios.get(getBackendURL() + "/api/v1/user/me").then(results => {
            let user:User = results.data
            setCurrentUser(user)

            if (!isDevelopmentEnv()) {
                posthog.identify(user.id, {
                    email: user.email,
                    name: user.firstName + " " + user.lastName
                })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <UserContext.Provider value={{currentUser, refreshUser}}>
            {children}
        </UserContext.Provider>
    )
}