import {useSubscriptionProvider} from "../../providers/SubscriptionProvider";
import PricingTable from "../../components/PricingTable";
import PageHeading from "../../components/PageHeading";
import HeadingDescriptor from "../../components/HeadingDescriptor";
import ProFeatures from "../../components/ProFeatures";
import {useState} from "react";
import toast, {LoaderIcon} from "react-hot-toast";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";

const SubscribePage = () => {
    const { validSubscription } = useSubscriptionProvider()

    const [creatingCustomerPortalLink, setCreatingCustomerPortalLink] = useState<boolean>(false)

    const createCustomerPortalLink = () => {
        setCreatingCustomerPortalLink(true);

        axios.post(getBackendURL() + "/api/v1/user/create-customer-portal-link").then(result => {
            window.location.href = result.data
        }).catch(error => {
            toast.error("En ukjent feil har oppstått")
            setCreatingCustomerPortalLink(false);
        })
    }

    return (
        <div className={"container mx-auto"}>
            {validSubscription ? (
                <div className={"flex flex-col w-full h-screen justify-center items-center"}>
                    <div className={"mb-10 space-y-2"}>
                        <h1 className={"text-4xl text text-main-darker font-medium"}>
                            Du har <span className={"text-yellow-500 font-bold underline underline-offset-4"}>pro</span> tilgang!
                        </h1>
                        <HeadingDescriptor className={"max-w-[400px]"}>
                            Dette gir deg tilgang på gode verktøy som lar deg raskere finne drømmeboligen!
                        </HeadingDescriptor>
                    </div>
                    <div className={"grid items-center"}>
                        <div className={"bg-white rounded-xl shadow-xl text-left"}>
                            <h1 className={"text-2xl text-main p-5"}>
                                Med ditt pro abonnement får du
                            </h1>
                            <hr/>
                            <div className={"p-5"}>
                                <ProFeatures/>
                            </div>
                        </div>
                        <div className={"max-w-96 space-y-5 mt-10 xl:mx-20"}>
                            <PageHeading>Administrer betalingsmåter og abonnement</PageHeading>
                            <HeadingDescriptor>
                                Du kan gjøre endringer på ditt abonnement i
                                kundeportalen.&nbsp;
                                <button
                                    className={"text-main underline underline-offset-4"}
                                    onClick={() => {createCustomerPortalLink()}}
                                    disabled={creatingCustomerPortalLink}
                                >
                                    {creatingCustomerPortalLink ? (
                                        <>
                                            <LoaderIcon />
                                        </>
                                    ) : (
                                        <>
                                            Gå til kundeportalen
                                        </>
                                    )}
                                </button>
                            </HeadingDescriptor>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={"flex flex-col xl:h-screen xl:justify-center"}>
                    <PricingTable/>
                </div>
            )}
        </div>
    )
}

export default SubscribePage;