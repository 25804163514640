import {useSubscriptionProvider} from "../providers/SubscriptionProvider";
import {useNavigate} from "react-router";

const Logo = ({className}:{className?: string}) => {
    const {validSubscription} = useSubscriptionProvider()
    const navigate = useNavigate()
    return (
        <div
            onClick={() => navigate("/")}
            className="relative">
            <img className={"cursor-pointer " + className} src={"/images/logo.webp"} alt="logo" />
            {validSubscription && (
                <div className={"absolute -bottom-4 right-0 font-bold text-yellow-400"}>
                    Pro
                </div>
            )}
        </div>
    )
}

export default Logo;