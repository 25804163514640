import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {Subscription} from "../types/Subscription";
import {SubscriptionStatus} from "../enums/SubscriptionStatus";

interface SubscriptionHolder {
    subscription?: Subscription | null
    validSubscription?: boolean
    getSubscription: () => void
}

export const SubscriptionContext = createContext<SubscriptionHolder>({
    getSubscription: () => {},
    subscription: undefined
})

export const useSubscriptionProvider = () => {
    const context = useContext(SubscriptionContext);
    if (!context) {
        throw new Error("useSubscriptionProvider must be used within a RequiresSubscription area");
    }
    return context;
}

export interface RequiresLicenseProps {
    children: React.ReactNode
}

export const SubscriptionProvider = ({children}: RequiresLicenseProps) => {
    const [subscription, setSubscription] = useState<Subscription | null | undefined>(undefined)
    const [validSubscription, setValidSubscription] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        getSubscription()
    }, []);

    const getSubscription = () => {
        axios.get(getBackendURL() + "/api/v1/subscription/").then(result => {
            let subscription:Subscription | undefined = result.data

            setSubscription(result.data)

            if (subscription && (subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.OVERDUE || subscription.status === SubscriptionStatus.FREE_TRIAL)) {
                setValidSubscription(true)
            }else {
                setValidSubscription(false)
            }
        }).catch(error => {
            setSubscription(null)
        })
    }

    return (
        <SubscriptionContext.Provider value={{subscription, validSubscription, getSubscription}}>
            {subscription === undefined && (
                <>

                </>
            )}

            {subscription !== undefined && (
                <>
                    {children}
                </>
            )}

        </SubscriptionContext.Provider>
    )
}