import {useUser} from "../providers/UserProvider";
import {useSubscriptionProvider} from "../providers/SubscriptionProvider";
import MainButton from "./MainButton";
import {useState} from "react";
import axios from "axios";
import {getBackendURL, isDevelopmentEnv} from "../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import posthog from "posthog-js";

interface UpgradeButtonProps {
    className?: string
}

const UpgradeButton = ({className}:UpgradeButtonProps) => {
    const {validSubscription} = useSubscriptionProvider()
    const {currentUser} = useUser()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const startTrialPressed = () => {
        if (!isDevelopmentEnv()) {
            posthog.capture('start_trial')
        }
        if (!currentUser) {
            navigate("/register")
            return
        }
        setIsLoading(true);

        axios.post(getBackendURL() + "/api/v1/subscription/subscribe").then(result => {
            window.location.href = result.data
        }).catch(error => {
            toast.error("Noe gikk galt")
        })
    }

    return (
        <>
            {!validSubscription && (
                <>
                    {currentUser?.hadTrial ? (
                        <MainButton
                            onClick={() => startTrialPressed()}
                            loading={isLoading}
                            className={"ml-0 mt-5 " + className}>
                            Oppgrader til <span className={"underline underline-offset-2"}>Pro</span> versjon
                        </MainButton>
                    ) : (
                        <MainButton
                            onClick={() => startTrialPressed()}
                            loading={isLoading}
                            className={"ml-0 mt-5 " + className}>
                            Start 3 dagers prøveperiode
                        </MainButton>
                    ) }
                </>
            )}
        </>
    )
}

export default  UpgradeButton