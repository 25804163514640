import UploadPDF from "./components/UploadPDF";

const UploadFilePage = () => {
    return (
        <div className={"flex flex-col justify-center items-center"}>
            <UploadPDF />
        </div>
    )
}

export default UploadFilePage;