import {CheckBadgeIcon, LockOpenIcon, ScaleIcon, UserIcon} from "@heroicons/react/24/solid";
import MainButton from "./MainButton";
import {useState} from "react";
import {useUser} from "../providers/UserProvider";
import {useNavigate} from "react-router";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import TooltipText from "./TooltipText";
import {Tooltip} from "@mui/material";
import ProFeatures from "./ProFeatures";
import UpgradeButton from "./UpgradeButton";

const PricingTable = () => {
    return (
        <div className={"grid xl:grid-cols-2 py-16 xl:py-40 items-center"}>
            <div className={"flex flex-col text-left items-center mx-4 mb-20"}>
                <div>
                    <h1 className={"text-main text-5xl font-bold"}>Lås opp alle funksjoner</h1>
                    <p className={"xl:w-[500px] text-gray-700 text-xl mt-5"}>
                        Få tilgang til hele spekteret av AI Salgsoppgave sine kraftfulle verktøy og gjør din
                        boligkjøpsprosess enda enklere.
                    </p>
                    <ul className={"mt-16 space-y-10 md:ml-7"}>
                        <li className={"xl:w-[400px] flex flex-row text-left space-x-4"}>
                            <ScaleIcon className={"w-[50px]"}/>
                            <p>
                                <strong>Sammenlign boliger: </strong>
                                Lagre boligene du liker best, og enkelt sammenlign de med hverandre.
                            </p>
                        </li>
                        <li className={"xl:w-[400px] flex flex-row text-left space-x-4"}>
                            <LockOpenIcon className={"w-[50px]"}/>
                            <p>
                                <strong>Beta tilgang: </strong>
                                Få eksklusiv tilgang til nye funksjoner før de blir tilgjengelige for andre brukere.
                            </p>
                        </li>
                        <li className={"xl:w-[400px] flex flex-row text-left space-x-4"}>
                            <UserIcon className={"w-[50px]"}/>
                            <p>
                                <strong>Prioritert kundeservice: </strong>
                                Få raskere hjelp med dine spørsmål og behov med vår prioriterte support.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"flex flex-col xl:max-w-[600px] mx-auto"}>
                <div className={"border-gray-300 border-[1px] shadow-2xl bg-white rounded-xl"}>
                    <div className={"grid grid-cols-2 items-center p-6"}>
                        <div className={"text-left"}>
                            <h2 className={"font-bold text-lg"}>
                                Pro Plan
                            </h2>
                            <p>
                                Vårt mest populære abonnement
                            </p>
                        </div>
                        <div className={"text-right"}>
                            <h2 className={"font-bold text-5xl"}>149 kr</h2>
                            <p className={"text-gray-600"}>per mnd.</p>
                        </div>
                    </div>
                    <hr/>
                    <div className={"p-6 text-left"}>
                        <ProFeatures />

                        <UpgradeButton
                            className={"w-full"}
                        />

                        <p className={"text-sm text-center text-gray-600 mt-3"}>Vi sender deg melding èn dag før prøveperioden slutter</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PricingTable;